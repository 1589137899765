<template>
  <div data-cy="success-summary-pay-in-club">
    <h1 class="text-2xl xl:text-4xl">{{ $t('success.heading', { gymName }) }}</h1>
    <section class="border-t theme-border-color mt-4 xl:mt-10">
      <p class="mt-3 xl:mt-6">
        {{ $t('success.payInClub.text1') }}
      </p>

      <p class="mt-4 xl:mt-8">
        {{ $t('success.payInClub.text2') }}
      </p>

      <p class="mt-4 xl:mt-8">{{ $t('success.payInClub.items.text') }}</p>
      <ul class="list-disc list-inside">
        <li class="mt-2">{{ $t('success.payInClub.items.identification') }}</li>
        <li class="mt-2">{{ $t('success.payInClub.items.payment') }}</li>
      </ul>
      <p class="mt-2">{{ $t('success.payInClub.items.footer') }}</p>

      <p class="mt-4 xl:mt-8" v-if="isReferral" id="referral-text">
        {{ $t('success.referralText') }}
      </p>

      <p class="mt-4 xl:mt-8">
        {{ $t('success.payInClub.text3') }}
      </p>

      <div>
        <TickIcon class="m-auto h-32 w-32 xl:h-56 xl:w-56 fill-current theme-text mt-5 xl:mt-10" />
      </div>
    </section>
  </div>
</template>

<script>
import { TickIcon } from '@membr/component-library';

export default {
  name: 'SuccessSummaryPayInClub',
  components: {
    TickIcon
  },
  props: {
    isReferral: { type: Boolean, required: false }
  },
  data() {
    return {
      gymName: this.$store.state.context.gymName
    };
  }
};
</script>
