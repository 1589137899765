export default {
  notFound: { key: 'notFound', terminal: true },
  pending: { key: 'pending' },
  succeeded: { key: 'succeeded', terminal: true },
  failed: { key: 'failed', terminal: true },
  submitted: { key: 'submitted', simulateOobCall: true },
  delayed: { key: 'delayed', terminal: true },
  cancelled: { key: 'cancelled', terminal: true },
  rejected: { key: 'rejected', terminal: true }
};
