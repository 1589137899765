import axios from 'axios';

const API_URL = process.env.VUE_APP_PHP_API_BASE_URL;

async function simulate(paymentIdentifier, reference, contactId, method) {
  if (!simulationEnabled()) return;

  const url = `${API_URL}private/callback/process/ddms?MembrID=${paymentIdentifier}&HarlandsID=${reference}&ContactID=${contactId}&Method=${method}`;
  await axios.get(url, {
    auth: {
      username: process.env.VUE_APP_PHP_PRIVATE_API_LOGIN,
      password: process.env.VUE_APP_PHP_PRIVATE_API_PASS
    }
  });
}

function simulationEnabled() {
  return process.env.VUE_APP_OOB_SIMULATION_ENABLED === 'true';
}

export default {
  simulate
};
