<template>
  <div data-cy="success-summary">
    <h1 class="text-2xl xl:text-4xl">{{ $t('parqFailure.heading') }}</h1>
    <p class="mt-10 mb-10">
      {{ $t('parqFailure.text') }}
    </p>

    <h6 class="mt-4 text-1xl" id="phone-label">{{ $t('parqFailure.callLabel') }}</h6>
    <p class="text-2xl xl:text-2xl text-black" id="phone-number">{{ this.phoneNumber }}</p>
    <h6 class="mt-4 text-1xl" id="email-label">{{ $t('parqFailure.EmailLabel') }}</h6>
    <p class="text-2xl xl:text-2xl text-black" id="email-address">{{ this.emailAddress }}</p>
  </div>
</template>

<script>
export default {
  name: 'FailedSummary',
  data() {
    return {
      phoneNumber: this.$store.state.context.phone,
      emailAddress: this.$store.state.context.email
    };
  }
};
</script>
