<template>
  <div>
    <h1 class="text-xl xl:text-4xl">
      {{ $t('failure.heading') }}
    </h1>
    <p class="mt-2">
      {{ $t('failure.text') }}
    </p>
  </div>
</template>

<script>
export default {
  name: 'FailureSummary'
};
</script>
