<template>
  <div data-cy="success-summary-mpay">
    <h1 class="text-2xl xl:text-4xl">{{ $t('success.heading-mpay', { gymName }) }}</h1>
    <p class="mt-2">
      {{ $t('success.text-mpay') }}
    </p>

    <p class="mt-4 xl:mt-8" v-if="isParqEnabled" id="parq-text">This gym has parq enabled.</p>

    <section class="border-t theme-border-color mt-4 xl:mt-10">
      <a
        v-if="showMemberLoginButton"
        :href="websiteUrl"
        id="login"
        class="btn theme-button-alternative mt-10 xl:mt-20 w-full md:w-auto"
      >
        {{ $t('success.login-mpay') }}
      </a>
    </section>
  </div>
</template>

<script>
export default {
  name: 'SuccessSummaryMPay',
  props: {
    isReferral: { type: Boolean, required: false },
    isParqEnabled: { type: Boolean }
  },
  data() {
    return {
      gymName: this.$store.state.context.gymName,
      websiteUrl: this.$store.state.context.website,
      showMemberLoginButton: this.$store.state.context.memberSignup.showMemberLoginButtonOnSuccessPage
    };
  }
};
</script>
