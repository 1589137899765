function initialize() {
  let prevState = {};
  if (isInitialized()) {
    prevState = getConsentState();
  } else {
    window.addEventListener('UC_UI_INITIALIZED', () => {
      prevState = getConsentState();
    });
  }

  window.addEventListener('ucEvent', handleEvent);

  function isInitialized() {
    return window.UC_UI && window.UC_UI.isInitialized();
  }

  /**
   * https://docs.usercentrics.com/#/cmp-v2-ui-api?id=getservicesbaseinfo
   */
  function getConsentState() {
    const state = {};

    window.UC_UI.getServicesBaseInfo().forEach(service => {
      state[service.name] = service.consent.status;
    });

    return state;
  }

  function hasRevokedConsents(origObj, newObj) {
    for (let key in origObj) {
      if (origObj.hasOwnProperty(key) && origObj[key] === true && newObj.hasOwnProperty(key) && newObj[key] === false) {
        return true;
      }
    }

    return false;
  }

  /**
   * https://docs.usercentrics.com/#/v2-events?id=consent-events
   */
  function handleEvent(/*ucEvent*/) {
    if (!isInitialized()) {
      return;
    }

    const currentState = getConsentState();

    if (hasRevokedConsents(prevState, currentState)) {
      window.location.reload();
    }

    prevState = currentState;
  }
}

export const UserCentrics = {
  initialize
};
