import paymentStatusApi from '@/api/payment-status-api';
import paymentOobApi from '@/api/payment-oob-api';

async function pollStatus(
  gymId,
  enableParqQuestionOnSignup,
  paymentIdentifier,
  statusChangedCallback,
  pollingInterval,
  lastStatus = undefined
) {
  const currentStatus = await paymentStatusApi.get(gymId, paymentIdentifier);

  if (
    statusHasChanged(currentStatus, lastStatus) &&
    (currentStatus.hash !== null || enableParqQuestionOnSignup !== true)
  ) {
    var statusChangedEvent = createStatusChangedEvent(currentStatus);
    statusChangedCallback(statusChangedEvent);

    lastStatus = currentStatus;

    if (currentStatus.value.simulateOobCall) {
      await paymentOobApi.simulate(
        paymentIdentifier,
        currentStatus.reference,
        currentStatus.contactId,
        currentStatus.method
      );
    }
  }

  if (!currentStatus.value.terminal || (currentStatus.hash === null && enableParqQuestionOnSignup === true)) {
    setTimeout(
      () =>
        pollStatus(
          gymId,
          enableParqQuestionOnSignup,
          paymentIdentifier,
          statusChangedCallback,
          pollingInterval,
          lastStatus
        ),
      pollingInterval
    );
  }
}

function statusHasChanged(currentStatus, lastStatus) {
  return currentStatus.value !== lastStatus?.value;
}

function createStatusChangedEvent(currentStatus) {
  return {
    value: currentStatus.value,
    ...(currentStatus.userId && { userId: currentStatus.userId }),
    ...(currentStatus.error && { error: currentStatus.error }),
    ...(currentStatus.paymentIdentifier && { paymentIdentifier: currentStatus.paymentIdentifier }),
    ...(currentStatus.ongoingPaymentType && { ongoingPaymentType: currentStatus.ongoingPaymentType }),
    ...(currentStatus.isReferral && { isReferral: currentStatus.isReferral }),
    ...(currentStatus.isMPayStripe && { isMPayStripe: currentStatus.isMPayStripe }),
    ...(currentStatus.isParqEnabled && { isParqEnabled: currentStatus.isParqEnabled }),
    ...(currentStatus.isInductionEnabled && { isInductionEnabled: currentStatus.isInductionEnabled }),
    ...(currentStatus.hash && { hash: currentStatus.hash })
  };
}

export default { pollStatus };
