<template>
  <form @submit.prevent="submitSpecialCode" ref="form" class="flex items-center">
    <label
      for="special-code-input-id"
      class="text-lg mr-6 hidden sm:block"
      :style="{ color: isMpayClub || isAtfClub ? '' : brandingSecondaryColour }"
      >{{ $t(inputLabelTranslationKey) }}</label
    >
    <input
      id="special-code-input-id"
      class="form-input promo-code-input md:w-64 w-full min-w-20"
      type="text"
      :data-cy="generateDataCyValue('input')"
      :placeholder="$t(inputLabelTranslationKey)"
      :disabled="disabled"
      v-model.lazy="$v.code.$model"
      @blur="$v.code.$model = $event.target.value.trim()"
      @focus="$emit('input-focus')"
      v-on:keyup.esc="$emit('close')"
      v-focus
    />
    <button
      type="submit"
      :data-cy="generateDataCyValue('button')"
      v-if="!disabled"
      class="btn theme-button-primary promo-code-submit md:mx-6 mx-2"
      @submit.prevent="submitSpecialCode"
    >
      {{ $t('membershipPackages.specialPackagesLoader.checkButton') }}
    </button>
    <button type="button" :data-cy="generateDataCyValue('close')" v-if="!disabled" @click.prevent.stop="$emit('close')">
      <CloseIcon
        class="h-8 w-8 fill-current theme-alternate-text"
        :style="{ color: isMpayClub || isAtfClub ? '' : brandingSecondaryColour }"
      />
    </button>
    <div class="mx-3">
      <Loader v-if="disabled" />
    </div>
    <p
      v-if="$v.code.$error && !$v.code.required"
      class="validation-message validation-required theme-validation"
      :data-cy="generateDataCyValue('validation-message')"
    >
      {{ $t(requiredMessageTranslationKey) }}
    </p>
  </form>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import { CloseIcon, Loader } from '@membr/component-library';

export default {
  name: 'SpecialCodeInput',
  components: {
    Loader,
    CloseIcon
  },
  data() {
    return {
      code: this.initialValue ? this.initialValue : null
    };
  },
  props: {
    inputLabelTranslationKey: { type: String, required: true },
    requiredMessageTranslationKey: { type: String, required: true },
    initialValue: { type: String, required: false },
    disabled: { type: Boolean, required: false },
    specialCodeType: { type: String, required: false }
  },
  computed: {
    brandingSecondaryColour() {
      return this.$store.state.context.branding.brandingSecondaryColour;
    },
    isAtfClub() {
      return this.$store.state.context.isAtfCompany;
    },
    isMpayClub() {
      return this.$store.state.context.isMpayCompany;
    }
  },
  methods: {
    submitSpecialCode() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      this.$emit('special-code-submitted', this.$v.code.$model);
    },
    generateDataCyValue(suffix) {
      return `${this.specialCodeType}-${suffix}`;
    }
  },
  validations: {
    code: {
      required
    }
  }
};
</script>
