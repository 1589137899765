<template>
  <keep-alive :include="'MembershipPackages,PersonalDetails,MembershipSetup'">
    <router-view :key="cacheKey" />
  </keep-alive>
</template>

<script>
import Vue from 'vue';

export default {
  name: 'Route.vue',
  computed: {
    cacheKey() {
      // The keep-alive is used to cache each view so that the state is kept if the user goes back and forward.
      // However, if the user goes back to the beginning and selects a new package then the journey downstream
      // is not updated and could be invalid. The cacheKey is a key based on the route name and packageId that
      // uniquely identifies each view so that when a new package is selected the journey downstream will not
      // be cached.
      const packageId = this.$store.state?.membershipPackage?.packageId ?? '';
      return this.$route.name + packageId;
    }
  }
};
</script>

<style scoped></style>
