<template>
  <div>
    <div v-if="!isAnswerSubmitted && token">
      <div data-cy="success-summary" class="flex flex-col gap-3">
        <h1 class="text-2xl xl:text-4xl items-center">{{ $t('parqQuestion.heading') }}</h1>
        <p>
          {{ $t('parqQuestion.subHeading', { gymName }) }}
        </p>
        <p id="referral-text">
          {{ $t('parqQuestion.text') }}
        </p>
        <p id="phone-text">
          {{ $t('parqQuestion.subText', { phoneNumber }) }}
        </p>
      </div>
      <section class="border-t theme-border-color mt-4 xl:mt-10" v-if="parq">
        <form class="flex flex-col mt-4 xl:mt-10 gap-8" @submit.prevent="submitParqQuestions">
          <div class="header-info">
            <h2 class="text-xl xl:text-1xl">{{ parq.title }}</h2>
          </div>
          <div class="flex flex-col gap-10">
            <div
              class="question-item flex flex-col gap-4 border-l-4 pl-6"
              :class="{
                'border-red-400': $v.questions.$each[i].answer.$error,
                'theme-border-color': !$v.questions.$each[i].answer.$error
              }"
              v-for="(question, i) in questions"
              :key="question.id"
            >
              <p v-html="question.question"></p>
              <div class="flex flex-row items-center gap-12">
                <label class="flex flex-row items-center">
                  <input
                    type="radio"
                    class="form-radio form-radio-lg"
                    :name="'question' + question.id"
                    value="yes"
                    v-model="question.answer"
                  />
                  {{ $t('parqQuestion.yes') }}
                </label>
                <label>
                  <input
                    type="radio"
                    class="form-radio form-radio-lg"
                    :name="'question' + question.id"
                    value="no"
                    v-model="question.answer"
                  />
                  {{ $t('parqQuestion.no') }}
                </label>
              </div>
              <div class="theme-validation" v-if="$v.questions.$each[i].answer.$error">
                {{ $t('parqQuestion.required-error') }}
              </div>
            </div>
            <div class="theme-validation" v-if="$v.questions.$anyError">
              {{ $t('parqQuestion.please-answer-all-the-questions') }}
            </div>
          </div>
          <div class="clearfix border-t theme-border-color"></div>
          <div class="items-center flex justify-center">
            <button type="submit" class="btn theme-button-primary next" id="next">
              {{ $t('continue') }}
            </button>
          </div>
        </form>
      </section>
    </div>

    <component v-if="(isAnswerSubmitted || isHashDeclined) && mapParqToComponent()" :is="mapParqToComponent()" />

    <div v-if="!token && !isHashDeclined">
      <h1 class="text-xl xl:text-4xl pb-8 flex justify-center">
        {{ $t('status.heading') }}
      </h1>
      <Loader id="polling-loader" :title="$t('payment.processing')" />
    </div>
  </div>
</template>

<script>
import parqQuestionsApi from '@/api/parq-questions-api';
import SuccessSummary from '@/components/parq-status/SuccessSummary.vue';
import SuccessInductionSummary from '@/components/parq-status/SuccessInductionSummary.vue';
import FailedSummary from '@/components/parq-status/FailedSummary.vue';
import DeclinedSuccessSummary from '@/components/status/SuccessSummary.vue';
import DeclinedSuccessSummaryPayInClub from '@/components/status/SuccessSummaryPayInClub.vue';
import DeclinedSuccessSummaryMPay from '@/components/status/SuccessSummaryMPay.vue';
import { required } from 'vuelidate/lib/validators';
import { Loader } from '@membr/component-library';

export default {
  name: 'ParqQuestionsSummary',
  components: {
    SuccessSummary,
    FailedSummary,
    SuccessInductionSummary,
    DeclinedSuccessSummary,
    DeclinedSuccessSummaryPayInClub,
    DeclinedSuccessSummaryMPay,
    Loader
  },
  props: {
    isReferral: { type: Boolean, required: false },
    isParqEnabled: { type: Boolean },
    isInductionEnabled: { type: Boolean },
    isMPayStripe: { type: Boolean },
    finalizeInClub: { type: Boolean }
  },
  data() {
    return {
      gymName: this.$store.state.context.gymName,
      phoneNumber: this.$store.state.context.phone,
      parq: null,
      questions: [],
      isAnswerCheck: false,
      isAnswerSubmitted: false,
      submitAttempted: false,
      userCallProcess: false,
      isHashDeclined: false
    };
  },
  async created() {
    await this.getUserHashToken();
  },
  computed: {
    gymId() {
      return this.$store.state.context.gymId;
    },
    hostname() {
      return this.$store.state.hostname;
    },
    userId() {
      return this.$store.state.userId;
    },
    hash() {
      return this.$store.state.hash;
    },
    token() {
      return this.$store.state.token;
    }
  },
  validations: {
    questions: {
      $each: { answer: { required } }
    }
  },
  methods: {
    async getUserHashToken() {
      if (this.hash && !this.userCallProcess) {
        this.userCallProcess = true;

        try {
          const response = await parqQuestionsApi.getUserHashToken(this.hash);

          this.token = response.token;
          this.$store.commit('setToken', response.token);
          this.getParqQuestions();
        } catch (e) {
          this.isHashDeclined = true;
        }
      }
    },
    async getParqQuestions() {
      const parqConfig = await parqQuestionsApi.getParqQuestions(this.gymId, this.token);

      this.parq = {
        title: parqConfig.title,
        description: parqConfig.description
      };

      this.questions = parqConfig.questions;
    },
    async submitParqQuestions() {
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      }

      const answers = this.questions.map(question => ({
        parqQuestion: question.id,
        answer: question.answer
      }));

      this.isAnswerCheck = answers.some(item => item.answer === 'yes');

      let response = await parqQuestionsApi.createParqQuestions(answers, this.gymId, this.token);

      if (response.status === 200) {
        this.isAnswerSubmitted = true;
      }
    },
    mapParqToComponent() {
      if (this.isHashDeclined) {
        if (this.finalizeInClub) {
          return 'DeclinedSuccessSummaryPayInClub';
        } else if (this.isMPayStripe) {
          return 'DeclinedSuccessSummaryMPay';
        } else {
          return 'DeclinedSuccessSummary';
        }
      } else {
        if (!this.isAnswerCheck && this.isInductionEnabled) {
          return 'SuccessInductionSummary';
        } else if (!this.isAnswerCheck) {
          return 'SuccessSummary';
        } else {
          return 'FailedSummary';
        }
      }
    }
  }
};
</script>
