import Vue from 'vue';
import Router from 'vue-router';
import MembershipPackages from '@/views/MembershipPackages.vue';
import EmailConfirmation from '@/views/EmailConfirmation';
import Status from '@/views/Status';
import Route from '@/components/Route.vue';
import store from '@/store';

Vue.use(Router);

const backToStart = (to, next) => {
  next({ path: `/${to.params.hostname}`, replace: true });
};

const getPromoCodeParam = route => {
  if (route.query.promo) return route.query.promo;
  if (route.query.promocode) return route.query.promocode;

  return null;
};

// Prevent bfcache from restoring payment pages as it might lead to duplicate charges
window.addEventListener('pageshow', event => {
  if (event.persisted && isPaymentPage(event.target.location)) {
    location.reload();
  }
});

function isPaymentPage(location) {
  return location.pathname.endsWith('/payment') || location.pathname.endsWith('/payment-one-off');
}

function evaluateBooleanQueryParameter(param) {
  return param !== undefined && (param === null || JSON.parse(param));
}

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: '/:hostname',
      component: Route,
      children: [
        {
          path: '',
          redirect: { name: 'packages' }
        },
        {
          path: '/',
          redirect: { name: 'packages' }
        },
        {
          path: 'packages',
          name: 'packages',
          component: MembershipPackages,
          props: route => ({
            promoCodeInitial: getPromoCodeParam(route),
            referralCodeInitial: route.query.referral
          })
        },
        {
          path: 'rejoin/:generatedHash',
          name: 'packages',
          component: MembershipPackages
        },
        {
          path: 'personal-details',
          name: 'personalDetails',
          component: () => import('@/views/PersonalDetails.vue'),
          beforeEnter: (to, from, next) => {
            if (!store.state.membershipPackage) {
              backToStart(to, next);
              return;
            }
            next();
          }
        },
        {
          path: 'membership',
          name: 'membership',
          component: () => import('@/views/MembershipSetup.vue'),
          beforeEnter: (to, from, next) => {
            if (!store.state.membershipPackage || !store.state.member) {
              backToStart(to, next);
              return;
            }
            next();
          }
        },
        {
          path: 'membership-agreement',
          name: 'membership-agreement',
          component: () => import('@/views/MembershipAgreement.vue'),
          beforeEnter: (to, from, next) => {
            if (!store.state.membershipPackage || !store.state.member) {
              backToStart(to, next);
              return;
            }
            next();
          }
        },
        {
          path: 'payment-one-off',
          name: 'paymentOneOff',
          component: () => import('@/views/PaymentOneOff.vue'),
          beforeEnter: (to, from, next) => {
            if (!store.state.membershipPackage || !store.state.member) {
              backToStart(to, next);
              return;
            }
            next();
          }
        },
        {
          path: 'payment',
          name: 'payment',
          component: () => import('@/views/Payment.vue'),
          beforeEnter: (to, from, next) => {
            if (!store.state.membershipPackage || !store.state.member) {
              backToStart(to, next);
              return;
            }
            next();
          }
        },
        {
          path: 'status',
          name: 'status',
          component: Status,
          props: route => ({
            paymentIdentifier: route.query.uid,
            statusProp: route.query.status,
            finalizeInClubProp: evaluateBooleanQueryParameter(route.query.finalizeInClub),
            isReferralProp: evaluateBooleanQueryParameter(route.query.referral)
          })
        },
        {
          path: 'confirm',
          name: 'confirm',
          component: EmailConfirmation,
          props: route => ({
            token: route.query.token
          })
        }
      ]
    }
  ]
});
