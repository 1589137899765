import axios from 'axios';

import rejoinRequestMapper from '@/api/mapper/request/rejoin-mapper';
import rejoinResponseMapper from '@/api/mapper/response/rejoin-mapper';

const PHP_API_URL = process.env.VUE_APP_PHP_API_BASE_URL;

export default {
  async getUserAndToken(hash, gymId) {
    const url = `${PHP_API_URL}public/get-user-from-hash`;
    const payload = rejoinRequestMapper.map(hash, gymId);
    const response = await axios.post(url, payload);
    return rejoinResponseMapper.map(response.data);
  }
};
