import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    hostname: null,
    context: null,
    membershipPackage: null,
    member: null,
    memberGuid: null,
    membershipSetup: null,
    usedReferralCode: null,
    token: null,
    userId: null,
    hash: null,
    error: null,
    isMpayRejoin: false,
    registrationCookie: null,
    isCookieSet: false,
    agreementPayload: {},
    documents: null
  },
  mutations: {
    setHostname(state, hostname) {
      state.hostname = hostname;
    },
    setMembershipPackage(state, membershipPackage) {
      state.membershipPackage = membershipPackage;
    },
    setMember(state, member) {
      state.member = member;
    },
    setMembershipSetup(state, membershipSetup) {
      state.membershipSetup = membershipSetup;
    },
    setContext(state, context) {
      state.context = context;
    },
    setUsedReferralCode(state, referralCode) {
      state.usedReferralCode = referralCode;
    },
    setToken(state, token) {
      state.token = token;
    },
    setUserId(state, userId) {
      state.userId = userId;
    },
    setHash(state, hash) {
      state.hash = hash;
    },
    setError(state, error) {
      state.error = error;
    },
    setMemberGuid(state, memberGuid) {
      state.memberGuid = memberGuid;
    },
    setMpayRejoin(state, isRejoin) {
      state.isMpayRejoin = isRejoin;
    },
    setRegistrationCookie(state, cookie) {
      state.registrationCookie = cookie;
      state.isCookieSet = true;
    },
    updateAgreementPayload(state, obj) {
      state.agreementPayload = { ...this.state.agreementPayload, ...obj };
    },
    setDocuments(state, documents) {
      state.documents = documents;
    }
  }
});
