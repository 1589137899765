import paymentStatuses from '@/api/payment-statuses';

function map(response) {
  switch (response.status) {
    case 404:
      return {
        value: paymentStatuses.notFound
      };
    case 200:
      return mapPaymentStatus(response.data);
    default:
      throw `Unrecognised response status '${response.status}'.`;
  }
}

function mapPaymentStatus(data) {
  switch (data.status) {
    case 'pending':
      return {
        value: paymentStatuses.pending
      };
    case 'submitted':
      return {
        value: paymentStatuses.submitted,
        reference: data.reference,
        contactId: data.contact_id,
        method: data.ddms_payment_method
      };
    case 'delayed':
      return {
        value: paymentStatuses.delayed,
        reference: data.reference,
        contactId: data.contact_id
      };
    case 'succeeded':
      return {
        value: paymentStatuses.succeeded,
        reference: data.reference,
        contactId: data.contact_id,
        userId: data.user_id,
        ongoingPaymentType: data.ongoing_payment_type,
        isReferral: data.is_referral,
        isMPayStripe: data.is_mpay_stripe,
        isParqEnabled: data.is_parq_enabled,
        isInductionEnabled: data.is_induction_enabled,
        hash: data.hash
      };
    case 'failed':
      return {
        value: paymentStatuses.failed,
        reference: data.reference,
        contactId: data.contact_id,
        error: data.error
      };
    case 'cancelled':
      return {
        value: paymentStatuses.cancelled
      };
    case 'rejected':
      return {
        value: paymentStatuses.rejected
      };
    default:
      throw `Unrecognised payment status '${data.status}'.`;
  }
}

export default { map };
