import axios from 'axios';
import parqQuestionsMapper from '@/api/mapper/response/parq-questions-mapper';
import parqQuestionsRequestMapper from '@/api/mapper/request/parq-questions-mapper';
import userRequestTokenMapper from '@/api/mapper/request/user-token-mapper';
import userResponseTokenMapper from '@/api/mapper/response/user-token-mapper';

const PHP_API_URL = process.env.VUE_APP_PHP_API_BASE_URL;
const API_URL = process.env.VUE_APP_API_BASE_URL;

export default {
  async getUserHashToken(hash) {
    const url = `${PHP_API_URL}public/generate-token`;
    const payload = userRequestTokenMapper.map(hash);

    const response = await axios.post(url, payload);

    return userResponseTokenMapper.map(response.data);
  },
  async getParqQuestions(gymId, token) {
    const url = `${PHP_API_URL}parq/gym?gym=${gymId}`;
    const config = {
      ...(token && { headers: { Authorization: `Bearer ${token}` } })
    };

    const response = await axios.get(url, config);
    return parqQuestionsMapper.map(response.data);
  },
  async createParqQuestions(answers, gymId, token) {
    const url = `${PHP_API_URL}parq?gym=${gymId}`;
    const payload = parqQuestionsRequestMapper.map(answers);
    const config = {
      ...(token && { headers: { Authorization: `Bearer ${token}` } })
    };
    const response = await axios.post(url, payload, config);
    return response.data;
  }
};
