import axios from 'axios';
import contextMapper from '@/api/mapper/response/context-mapper';

const API_URL = process.env.VUE_APP_API_BASE_URL;

export default {
  async getContextFromHostname(hostname) {
    const url = `${API_URL}config?hostname=${hostname}&source=online`;
    const response = await axios.get(url, { withCredentials: true });
    const config = response.data.data;

    if (!config) {
      return null;
    }

    return contextMapper.map(config);
  }
};
