<template>
  <div data-cy="success-summary">
    <h1 class="text-2xl xl:text-4xl">{{ $t('parqInductionSuccess.heading') }}</h1>
    <p class="mt-10 mb-10">
      {{ $t('parqInductionSuccess.text') }}
    </p>

    <section class="border-t theme-border-color mt-4 xl:mt-10 flex justify-center">
      <a :href="loginUrl" id="login" class="btn theme-button-alternative mt-10 xl:mt-20 w-full md:w-auto">
        {{ $t('parqInductionSuccess.buttonLabel') }}
      </a>
    </section>
  </div>
</template>

<script>
export default {
  name: 'SuccessInductionSummary',
  data() {
    return {
      gymName: this.$store.state.context.gymName,
      loginUrl:
        this.$store.state.context.memberSignup.loginUrl.replace('/login', '') +
        '?token=' +
        this.$store.state.token +
        '&hostname=' +
        this.$store.state.hostname +
        '&source=online'
    };
  }
};
</script>
