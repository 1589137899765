import axios from 'axios';

const API_URL = process.env.VUE_APP_API_BASE_URL;

export default {
  async confirm(token) {
    const url = `${API_URL}email/confirm?token=${token}`;

    await axios.get(url, { withCredentials: true });
  }
};
