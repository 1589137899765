import rejoinApi from '@/api/rejoin-api';

async function pollStatus(hash, gymId, userChangedCallback, pollingInterval) {
  const response = await rejoinApi.getUserAndToken(hash, gymId);

  if (response.user) {
    userChangedCallback(response.user, response.token);
  } else {
    setTimeout(() => pollStatus(hash, statusChangedCallback, pollingInterval), pollingInterval);
  }
}

export default { pollStatus };
