<template>
  <div :style="{ color: brandingSecondaryColour }">
    <h1 class="text-xl xl:text-4xl">
      {{ $t('delayed.heading') }}
    </h1>
    <p class="mt-2">
      {{ $t('delayed.text') }}
    </p>
    <p v-if="showRefreshWarning" id="refresh-warning" class="mt-2">
      {{ $t('delayed.refreshWarning') }}
    </p>
    <p v-else id="refresh-status" class="mt-2">
      {{ $t('delayed.refreshStatus') }}
    </p>
  </div>
</template>

<script>
export default {
  name: 'DelaySummary',
  props: {
    showRefreshWarning: {
      type: Boolean,
      required: false
    }
  },
  computed: {
    brandingSecondaryColour() {
      return this.$store.state.context.branding.brandingSecondaryColour;
    }
  }
};
</script>
