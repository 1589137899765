import axios from 'axios';
import membershipPackageMapper from '@/api/mapper/response/membership-package-mapper';

const API_URL = process.env.VUE_APP_API_BASE_URL;

export default {
  async getMembershipPackages(gymId) {
    const url = `${API_URL}gym/${gymId}/online-signup-packages`;
    const response = await axios.get(url, { withCredentials: true });

    return response.data.data.map(membershipPackageMapper.map);
  },

  async getPromoMembershipPackages(gymId, promoCode) {
    const url = `${API_URL}gym/${gymId}/online-signup-promo-packages`;
    const params = {
      code: promoCode
    };

    const response = await axios.get(url, { params, withCredentials: true });

    return response.data.data.map(membershipPackageMapper.map);
  },

  async getReferralMembershipPackages(gymId, referralCode) {
    const url = `${API_URL}gym/${gymId}/online-signup-referral-packages`;
    const params = {
      code: referralCode
    };

    const response = await axios.get(url, { params, withCredentials: true });

    return response.data.data.map(membershipPackageMapper.map);
  }
};
