import { init } from '@elastic/apm-rum';

export default {
  install(Vue, options) {
    const apm = init({
      serviceName: options.serviceName,
      serverUrl: process.env.VUE_APP_ELASTIC_URL,
      serviceVersion: process.env.VUE_APP_BUILD_NUMBER,
      environment: process.env.VUE_APP_ELASTIC_ENV
    });

    apm.addLabels({ region: process.env.VUE_APP_REGION });

    apm.observe('transaction:end', tr => {
      if (tr.name === 'Unknown') {
        tr.name = window.location.pathname;
      }
    });

    Vue.config.errorHandler = (error, vm, info) => {
      apm.captureError(error);
      /* eslint-disable-next-line no-console */
      console.error(error, vm, info);
    };

    window.onerror = function (message, source, lineno, colno, error) {
      apm.captureError(error);
    };
  }
};
