<template>
  <div data-cy="success-summary">
    <h1 class="text-2xl xl:text-4xl">{{ $t('parqSuccess.heading') }}</h1>
    <p class="mt-10 mb-10">
      {{ $t('parqSuccess.text') }}
    </p>

    <section class="border-t theme-border-color mt-4 xl:mt-10">
      <a :href="website" id="login" class="btn theme-button-alternative mt-10 xl:mt-20 w-full md:w-auto">
        {{ $t('parqSuccess.buttonLabel') }}
      </a>
    </section>
  </div>
</template>

<script>
export default {
  name: 'SuccessSummary',
  data() {
    return {
      website: this.$store.state.context.website
    };
  }
};
</script>
