<template>
  <div>
    <h1 class="text-xl xl:text-4xl">
      {{ $t('rejected.heading') }}
    </h1>
    <p class="mt-2">
      {{ $t('rejected.text') }}
    </p>
    <section class="border-t theme-border-color mt-6">
      <a :href="restartSignupUrl" id="restartSignup" class="btn theme-button-alternative mt-6 w-full md:w-auto">{{
        $t('rejected.restartSignup')
      }}</a>
    </section>
  </div>
</template>

<script>
export default {
  name: 'RejectSummary',
  computed: {
    restartSignupUrl() {
      return '/' + this.$store.state.hostname;
    }
  }
};
</script>
