import axios from 'axios';
import responseMapper from '@/api/mapper/response/payment-status-mapper';

const API_URL = process.env.VUE_APP_API_BASE_URL;

async function get(gymId, paymentIdentifier) {
  const url = `${API_URL}gym/${gymId}/payment/${paymentIdentifier}/status`;

  const response = await axios.get(url, { withCredentials: true });

  return responseMapper.map(response);
}

export default {
  get
};
