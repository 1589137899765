<template>
  <div data-cy="success-summary" :style="{ color: brandingSecondaryColour }">
    <h1 class="text-2xl xl:text-4xl">{{ $t('success.heading', { gymName }) }}</h1>
    <p class="mt-2">
      {{ $t('success.text') }}
    </p>

    <p class="mt-4 xl:mt-8" v-if="isReferral" id="referral-text">
      {{ $t('success.referralText') }}
    </p>

    <section class="border-t theme-border-color mt-4 xl:mt-10">
      <div>
        <TickIcon
          class="m-auto h-32 w-32 xl:h-56 xl:w-56 fill-current theme-text mt-4 xl:mt-10"
          :style="{ color: brandingSecondaryColour }"
        />
      </div>
      <a
        v-if="showMemberLoginButton"
        :href="loginUrl"
        id="login"
        class="btn theme-button-alternative mt-10 xl:mt-20 w-full md:w-auto"
        :style="{ background: brandingPrimaryColour }"
        >{{ $t('success.login') }}</a
      >
    </section>
  </div>
</template>

<script>
import { TickIcon } from '@membr/component-library';

export default {
  name: 'SuccessSummary',
  components: {
    TickIcon
  },
  props: {
    isReferral: { type: Boolean, required: false }
  },
  data() {
    return {
      gymName: this.$store.state.context.gymName,
      loginUrl: this.$store.state.context.memberSignup.loginUrl,
      showMemberLoginButton: this.$store.state.context.memberSignup.showMemberLoginButtonOnSuccessPage
    };
  },
  computed: {
    brandingPrimaryColour() {
      return this.$store.state.context.branding.brandingPrimaryColour;
    },
    brandingSecondaryColour() {
      return this.$store.state.context.branding.brandingSecondaryColour;
    }
  }
};
</script>
