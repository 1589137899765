<template>
  <div>
    <div v-if="token" data-cy="success-induction-summary">
      <h1 class="text-2xl xl:text-4xl">{{ $t('inductionDetails.heading') }}</h1>

      <p class="mt-3 xl:mt-8" id="sub-heading">
        {{ $t('inductionDetails.subHeading', { gymName }) }}
      </p>
      <p class="mt-3 xl:mt-3" id="referral-text">
        {{ $t('inductionDetails.text') }}
      </p>
      <p class="mt-3 xl:mt-3" id="phone-text">
        {{ $t('inductionDetails.subText', { phoneNumber }) }}
      </p>

      <section class="border-t theme-border-color mt-4 xl:mt-10">
        <a
          :href="loginUrl + '?token=' + token + '&hostname=' + hostname + '&source=online'"
          id="login"
          class="btn theme-button-alternative mt-10 xl:mt-20 w-full md:w-auto"
        >
          {{ $t('inductionDetails.buttonLabel') }}
        </a>
      </section>
    </div>

    <component v-if="!token && isHashDeclined && mapInductionToComponent()" :is="mapInductionToComponent()" />

    <div v-if="!token && !isHashDeclined">
      <h1 class="text-xl xl:text-4xl pb-8 flex justify-center">
        {{ $t('status.heading') }}
      </h1>
      <Loader id="polling-loader" :title="$t('payment.processing')" />
    </div>
  </div>
</template>

<script>
import parqQuestionsApi from '@/api/parq-questions-api';
import DeclinedSuccessSummary from '@/components/status/SuccessSummary.vue';
import DeclinedSuccessSummaryPayInClub from '@/components/status/SuccessSummaryPayInClub.vue';
import DeclinedSuccessSummaryMPay from '@/components/status/SuccessSummaryMPay.vue';
import { Loader } from '@membr/component-library';

export default {
  name: 'InductionSummary',
  components: {
    DeclinedSuccessSummary,
    DeclinedSuccessSummaryPayInClub,
    DeclinedSuccessSummaryMPay,
    Loader
  },
  props: {
    isReferral: { type: Boolean, required: false },
    isParqEnabled: { type: Boolean },
    isInductionEnabled: { type: Boolean },
    isMPayStripe: { type: Boolean },
    finalizeInClub: { type: Boolean }
  },
  data() {
    return {
      gymName: this.$store.state.context.gymName,
      phoneNumber: this.$store.state.context.phone,
      userCallProcess: false,
      loginUrl: this.$store.state.context.memberSignup.loginUrl.replace('/login', ''),
      isHashDeclined: false
    };
  },
  async created() {
    await this.getUserHashToken();
  },
  computed: {
    hostname() {
      return this.$store.state.hostname;
    },
    userId() {
      return this.$store.state.userId;
    },
    hash() {
      return this.$store.state.hash;
    },
    token() {
      return this.$store.state.token;
    }
  },
  methods: {
    async getUserHashToken() {
      if (this.hash && !this.userCallProcess) {
        this.userCallProcess = true;

        try {
          const response = await parqQuestionsApi.getUserHashToken(this.hash);

          this.token = response.token;
          this.$store.commit('setToken', response.token);
        } catch (e) {
          this.isHashDeclined = true;
        }
      }
    },
    mapInductionToComponent() {
      if (this.finalizeInClub) {
        return 'DeclinedSuccessSummaryPayInClub';
      } else if (this.isMPayStripe) {
        return 'DeclinedSuccessSummaryMPay';
      } else {
        return 'DeclinedSuccessSummary';
      }
    }
  }
};
</script>
