import Vue from 'vue';
import App from './App.vue';
import '@membr/component-library/src/styles/main.css';
import '@membr/component-library/dist/component-library.css';

import Vuelidate from 'vuelidate';
import router from './router';
import store from './store';
import ElasticRum from './plugins/elastic-rum';
import i18n from './i18n';
import { FocusDirective } from '@membr/component-library';
import { NormalizeInputDirective } from '@membr/component-library';
import VueGtag from 'vue-gtag';
import VueSignaturePad from 'vue-signature-pad';

import '../node_modules/cropperjs/dist/cropper.css';

Vue.use(VueSignaturePad);
Vue.use(Vuelidate);
Vue.use(ElasticRum, {
  serviceName: 'OnlineSignup'
});
Vue.use(FocusDirective);
Vue.use(NormalizeInputDirective);
Vue.use(
  VueGtag,
  {
    appName: 'Online signup',
    //load this in App.vue when we have the tracking code
    bootstrap: false
  },
  router
);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app');
