<template>
  <div class="h-full flex items-center">
    <a
      @click="promoCodeInputVisible = true"
      v-if="!codeInputVisible"
      class="text-xl open-promo-input cursor-pointer mr-6"
      data-cy="show-promo-code-form"
      :style="{ color: isMpayClub || isAtfClub ? '' : brandingSecondaryColour }"
      >{{ $t('membershipPackages.promoCodeInputButton') }}</a
    >
    <SpecialCodeInput
      v-if="promoCodeInputVisible"
      :initial-value="promoCodeInitial"
      :disabled="loadingPackages"
      input-label-translation-key="membershipPackages.specialPackagesLoader.promoCodeInput.label"
      required-message-translation-key="membershipPackages.specialPackagesLoader.promoCodeInput.required"
      special-code-type="promo-code"
      @special-code-submitted="promoCodeSubmitted"
      @input-focus="clearValidation"
      @close="hideSpecialCodeInputLoader"
    />

    <a
      @click="referralCodeInputVisible = true"
      v-if="!codeInputVisible && !referralCodeAdded"
      class="text-xl open-referral-input cursor-pointer"
      data-cy="show-referral-code-form"
      :style="{ color: isMpayClub || isAtfClub ? '' : brandingSecondaryColour }"
      >{{ $t('membershipPackages.referralCodeInputButton') }}</a
    >
    <div v-if="referralCodeAdded" class="flex items-center">
      <TickIcon class="flex h-6 w-6 mr-1 fill-current theme-text" />
      <p class="text-xl">
        {{ $t('membershipPackages.referralCodeAdded') }}
      </p>
    </div>
    <SpecialCodeInput
      v-if="referralCodeInputVisible"
      :initial-value="referralCodeInitial"
      :disabled="loadingPackages"
      input-label-translation-key="membershipPackages.specialPackagesLoader.referralCodeInput.label"
      required-message-translation-key="membershipPackages.specialPackagesLoader.referralCodeInput.required"
      special-code-type="referral-code"
      @special-code-submitted="referralCodeSubmitted"
      @input-focus="clearValidation"
      @close="hideSpecialCodeInputLoader"
    />
    <p v-if="invalidPromoCode" class="validation-message validation-invalid-code theme-validation">
      {{ $t('membershipPackages.specialPackagesLoader.promoCodeInput.invalid') }}
    </p>
    <p v-if="invalidReferralCode" class="validation-message validation-invalid-code theme-validation">
      {{ $t('membershipPackages.specialPackagesLoader.referralCodeInput.invalid') }}
    </p>
  </div>
</template>

<script>
import membershipPackageApi from '@/api/membership-package-api';
import SpecialCodeInput from '@/components/membership-packages/SpecialCodeInput.vue';
import { TickIcon } from '@membr/component-library';

const PROMO_CODE_TYPE = 1;
const REFERRAL_CODE_TYPE = 2;

export default {
  name: 'SpecialPackagesLoader',
  components: {
    SpecialCodeInput,
    TickIcon
  },
  data() {
    return {
      promoCodeInputVisible: false,
      referralCodeInputVisible: false,
      invalidPromoCode: false,
      invalidReferralCode: false,
      loadingPackages: false,
      referralCodeAdded: false
    };
  },
  props: {
    promoCodeInitial: { type: String, required: false },
    referralCodeInitial: { type: String, required: false }
  },
  computed: {
    gymId() {
      return this.$store.state.context.gymId;
    },
    codeInputVisible() {
      return this.promoCodeInputVisible || this.referralCodeInputVisible;
    },
    brandingSecondaryColour() {
      return this.$store.state.context.branding.brandingSecondaryColour;
    },
    isAtfClub() {
      return this.$store.state.context.isAtfCompany;
    },
    isMpayClub() {
      return this.$store.state.context.isMpayCompany;
    }
  },
  async created() {
    if (this.promoCodeInitial) {
      this.promoCodeSubmitted(this.promoCodeInitial);
    } else if (this.referralCodeInitial) {
      this.referralCodeSubmitted(this.referralCodeInitial);
    }
  },
  methods: {
    hideSpecialCodeInputLoader() {
      this.promoCodeInputVisible = false;
      this.referralCodeInputVisible = false;
      this.clearValidation();
    },
    promoCodeSubmitted(promoCode) {
      this.loadPackages(promoCode, PROMO_CODE_TYPE);
    },
    referralCodeSubmitted(referralCode) {
      this.loadPackages(referralCode, REFERRAL_CODE_TYPE);
    },
    loadPackages(specialCode, codeType) {
      if (this.loadingPackages) {
        return;
      }
      this.loadingPackages = true;

      const packagesLoader = this.getPackageLoaderByCodeType(codeType);

      packagesLoader(specialCode).finally(() => {
        this.loadingPackages = false;
      });
    },
    getPackageLoaderByCodeType(codeType) {
      if (codeType === PROMO_CODE_TYPE) {
        return this.loadPromoPackages;
      } else if (codeType === REFERRAL_CODE_TYPE) {
        return this.loadReferralPackages;
      } else {
        throw 'Invalid code type';
      }
    },
    loadPromoPackages(promoCode) {
      return membershipPackageApi
        .getPromoMembershipPackages(this.gymId, promoCode)
        .then(packages => {
          if (!packages || packages.length == 0) {
            this.emitFailedToLoadPackagesEvent();
            this.showPromoCodeInvalidMessage();
            return;
          }

          this.emitPromoPackagesLoadedEvent(packages);
          this.hideSpecialCodeInputLoader();
        })
        .catch(() => {
          this.emitFailedToLoadPackagesEvent();
          this.showPromoCodeInvalidMessage();
        });
    },
    loadReferralPackages(referralCode) {
      return membershipPackageApi
        .getReferralMembershipPackages(this.gymId, referralCode)
        .then(packages => {
          this.emitReferralPackagesLoadedEvent(packages);
          this.setReferralCode(referralCode);
          this.hideSpecialCodeInputLoader();
        })
        .catch(() => {
          this.emitFailedToLoadPackagesEvent();
          this.showReferralCodeInvalidMessage();
        });
    },
    setReferralCode(referralCode) {
      this.$store.commit('setUsedReferralCode', referralCode);
      this.referralCodeAdded = true;
    },
    showPromoCodeInvalidMessage() {
      this.invalidPromoCode = true;
      this.promoCodeInputVisible = true;
    },
    showReferralCodeInvalidMessage() {
      this.invalidReferralCode = true;
      this.referralCodeInputVisible = true;
    },
    emitPromoPackagesLoadedEvent(packages) {
      this.$emit('promo-packages-loaded', packages);
    },
    emitReferralPackagesLoadedEvent(packages) {
      this.$emit('referral-packages-loaded', packages);
    },
    emitFailedToLoadPackagesEvent() {
      this.$emit('failed-to-load');
    },
    clearValidation() {
      this.invalidPromoCode = false;
      this.invalidReferralCode = false;
    }
  }
};
</script>
