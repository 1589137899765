import Vue from 'vue';
import VueI18n from 'vue-i18n';
import { locales } from '@membr/component-library';
import messages from '@/locales/en_GB';

Vue.use(VueI18n);

export default new VueI18n({
  fallbackLocale: locales.en_GB,
  messages: {
    en_GB: messages
  }
});
