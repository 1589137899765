<template>
  <div>
    <h1 class="text-xl xl:text-4xl">{{ $t('emailConfirmation.heading') }}</h1>

    <h2 v-if="!isComplete">{{ $t('emailConfirmation.validating') }}</h2>
    <h2 v-if="isComplete">{{ isSuccess ? $t('emailConfirmation.success') : $t('emailConfirmation.failure') }}</h2>

    <Loader v-if="!isComplete" />
  </div>
</template>

<script>
import { Loader } from '@membr/component-library';
import EmailConfirmationApi from '@/api/email-confirmation-api';

export default {
  name: 'EmailConfirmation',
  components: {
    Loader
  },
  async created() {
    this.isSuccess = await this.validateToken(this.token);
    this.isComplete = true;
  },
  data() {
    return {
      isComplete: false,
      isSuccess: false
    };
  },
  props: {
    token: { type: String, required: true }
  },
  methods: {
    async validateToken(token) {
      let isValid;
      try {
        await EmailConfirmationApi.confirm(token);

        isValid = true;
      } catch (error) {
        isValid = false;
      }
      return isValid;
    }
  }
};
</script>
