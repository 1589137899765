import store from '@/store';

let amplitude = window.amplitude;
const MAX_TRY = 3;

let trackingEnabled = false;

function initialize(track, apiKey) {
  trackingEnabled = track;
  let tryNo = 0;

  if (!trackingEnabled) {
    return;
  }

  if (amplitude !== undefined) {
    amplitude.init(apiKey, null, { serverZone: amplitude.Types.ServerZone.EU });
    setGlobalProperties();
  }

  if (undefined === amplitude) {
    if (tryNo >= MAX_TRY) {
      return;
    }

    amplitude = window.amplitude;
    setTimeout(function () {
      initialize(track, apiKey);
    }, 500);
    tryNo++;
  }
}

function setGlobalProperties() {
  const countryCode = store.state.context?.countryCode;
  const gymName = store.state.context?.gymName;
  const environment = store.state.context?.amplitude?.environment;

  const identifyObj = new amplitude.Identify();
  identifyObj.set('signup-type', 'online');
  identifyObj.set('gym-name', gymName);
  identifyObj.set('gym-country-code', countryCode);
  identifyObj.set('environment', environment);

  amplitude.identify(identifyObj);
}

async function raiseEvent(eventType, eventProperties) {
  if (!trackingEnabled) {
    return;
  }

  try {
    return await amplitude.logEvent(eventType, eventProperties);
  } catch (ex) {}
}

export const EventType = {
  SignupInitiated: 'signup-initiated',
  PackageSelected: 'package-selected',
  PersonalDetailsSubmitted: 'personal-details-submitted',
  MembershipConfigured: 'membership-configured',
  SignupCompleted: 'signup-completed'
};

export const Amplitude = {
  initialize,
  raiseEvent
};
